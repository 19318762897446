export function formatMoney(num,currencyCode) {
  const currencies = JSON.parse(window.localStorage.getItem("currencies") || '[]')
  let cc = JSON.parse(window.localStorage.getItem('currencyObj') || '{}')

  if(!num) return num;
  let number = num;
  if(typeof(number) === 'string') {
    number = number.replaceAll(',','')
    number = Number(number)
  }

  let rate = 1;
  let cr = window.localStorage.getItem("currencyRate");
  if(cr) {
    if(currencyCode) {
      if(cc.currencyCode !== currencyCode)
        rate = Number(cr);
    } else
      rate = Number(cr);
  }
  if(typeof(number) === 'number') {
    const requestedCurrencyRate = currencies?.find(obj => obj?.currencyCode === currencyCode)?.exchangeRateInNaira || 0
    if(currencyCode && (cc.currencyCode !== currencyCode) && requestedCurrencyRate)
      number *= requestedCurrencyRate
    else
      number /= rate;
    number = number.toFixed(2);
  }
  else if(typeof(number) === 'string') {
    number = num.replace(/\D/g, '');

    const isFloat = num.includes('.');
    if (isFloat) {
      number = parseFloat(num.replace(/[^\d.-]/g, ''));
    }

    // Perform operations on the extracted number if needed
    const modifiedNumber = (number/rate).toFixed(2);
    number = num.replace(number, modifiedNumber);

  }
  
  const [intPart, decimalPart] = number.toString().split(".");
  let formattedIntPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return decimalPart ? `${formattedIntPart}` : formattedIntPart;
}

export function convertMoney(num) {
  if(!num) return num;
  let number = num;
  let rate = 1;
  let cr = window.localStorage.getItem("currencyRate");
  if(cr)
    rate = Number(cr);
  if(typeof(number) === 'number') {
    number *= rate;
    number = number.toFixed(2);
  }
  else if(typeof(number) === 'string') {
    number = num.replace(/\D/g, '');

    const isFloat = num.includes('.');
    if (isFloat) {
      number = parseFloat(num.replace(/[^\d.-]/g, ''));
    }

    // Perform operations on the extracted number if needed
    const modifiedNumber = (number/rate).toFixed(2);
    number = num.replace(number, modifiedNumber);

  }
  
  const [intPart, decimalPart] = number.toString().split(".");
  let formattedIntPart = intPart;
  return decimalPart ? `${formattedIntPart}.${decimalPart}` : formattedIntPart;
}