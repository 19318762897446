import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react'
import { Tab, Tabs } from '@mui/material';
import FlightOfferDisplay from '../../../components/flight/FlightOfferDisplay';
import { FlightOfferDetail } from '../../../components/flight/FlightOfferDetail';
import { useNavigate, useSearchParams } from 'react-router-dom';
import getFlightOffers from '../../../controllers/search/getFlightOffers';
import { decrypt, encrypt } from '../../../features/utils/crypto';
import getFlightOfferPrice from '../../../controllers/flight/getOfferPrice';
import { useDispatch, useSelector } from 'react-redux';
import { setBookingData } from '../../../redux/reducers/flight/flightBookingSlice';
import FlightOfferFilter from './OffersFilter';
import FlightSearchInput from '../../../components/search/FlightSearchInput';
import moment from 'moment';
import { clone } from '../../../features/utils/objClone';
import { def } from '../../../config';
import PriceTimeout from '../../../components/flight/PriceTimeout';
import { formatMoney } from '../../../features/utils/formatMoney';
import splitCapitals from '../../../features/utils/splitCapital';
import getCalendarSearch from '../../../controllers/search/getCalendarSearch';
import ShareFlightOffer from '../../../components/flight/ShareFlightOffer';
import FlightLoading from '../../../components/flight/FlightLoading';
import DataLossModal from '../../../components/DataLossModal';


const tempCat = {
  Best: [],
  Cheapest: [],
  Fastest: [],
  EarlyDeparture: [],
  EarliestTakeOff: [],
  EarliestLanding: [],
  Slowest: [],
}
const tempFlightDate = [
  // {date: '2023-05-15',data: {},cost: '250,000'},
  // {date: '2023-05-16',data: {},cost: '250,000'},
  // {date: '2023-05-17',data: {},cost: '250,000'},
  // {date: '2023-05-18',data: {},cost: '250,000',active: true},
  // {date: '2023-05-19',data: {},cost: '250,000'},
  // {date: '2023-05-20',data: {},cost: '250,000'},
  // {date: '2023-05-21',data: {},cost: '250,000'},
]
const fetchedData = createRef([]);
export default function Index() {
  const [data,setData] = useState([]);
  const {bookingData} = useSelector(state => state.flightBooking);
  const [cat,setCat] = useState(tempCat);
  const [flightDate,setFlightDate] = useState(tempFlightDate);
  const [curDetail,setCurDetail] = useState();
  const [loading,setLoading] = useState(false);
  const [resMsg] = useState("No Result");
  const [searchParam] = useSearchParams();
  const q = useMemo(() => searchParam.get('q'),[searchParam]);
  const [searchType,setSearchType] = useState(1);
  const [lockSelect,setLockSelect] = useState(false);


  const [openDataLoss,setOpenDataLoss] = useState(false);


  const [pag,setPag] = useState({limit: 20})


  const navigate = useNavigate();


  console.log(' -----============-----------')
  console.log(flightDate)
  const curFlightDate = flightDate.findIndex(f => f.active)
  
  const dispatch = useDispatch();

  const fetchData = useCallback(async (req) => {
    if(!q) return {return: false};
    let obj = req || JSON.parse(decrypt(q));
    
    obj["nrOfRequestedResults"] = 30;
    obj["cwtCompanyName"] = bookingData?.cwt || undefined;
    if(!obj?.currencyOverride)
      obj.currencyOverride = window?.localStorage?.getItem('currency') || 'NGN'
    obj.destinations?.map(obj => {
      obj.departureLocation = obj.departureLocation?.iata || obj.departureLocation
      obj.arrivalLocation = obj.arrivalLocation?.iata || obj.arrivalLocation
      return true;
    })


    let userId = null;
    let userType = null;
    if(bookingData.as)
      userId = bookingData.as.id;
    if(bookingData?.userType)
      userType = bookingData?.userType

    return await getFlightOffers(obj,userId,userType);
  },[q,bookingData])

  useEffect(() => {
    let obj = JSON.parse(decrypt(q));
    setSearchType(obj.destinations.length || 1);
  },[q])

  useEffect(() => {
    function handleUnload(ev) {
      ev?.preventDefault();
      ev.returnValue = "";
        
      return "";
    }

    window.addEventListener('beforeunload', handleUnload)
    window.addEventListener('popstate', handleUnload)

    // return () => {
    //   window.removeEventListener('beforeunload', handleUnload)
    //   window.removeEventListener('popstate', handleUnload)
    // }

    //eslint-disable-next-line
  },[])


  const handleSetCat = useCallback((cat) => {
    if(q)
      setCat(cat)
  },[q])

  useEffect(() => {
    let t = null;

    async function load() {
      setLockSelect(false);
      setLoading(true);
      // let obj = JSON.parse(decrypt(q));
      // console.log(' -- ',q)
      // console.log(' -- ',obj)
      // const res = await getFlightOffers(obj);
      dispatch(setBookingData({...bookingData,time: null}))

      let {as,cwt,cwtObj,userType} = bookingData;

      const res = await fetchData();

      dispatch(setBookingData({as,cwt,userType,cwtObj,time: new Date().getTime()}))

      console.log('[p] res : ',res)
      setLoading(false);
  
      if(res.return) {

        let data = res.data?.map((obj,i) => ({...obj,i}));
        fetchedData.current = data;
        // console.log(res.cat)
        if(res.cat)
          handleSetCat(clone(res.cat))

        setData(data)

        // callDates(res);

      }
      // setResMsg(res.msg);
      // console.log(res);
    }  
    load();
    searchCalendars();

    async function callDates(res) {
      if(!q) return {return: false};
  
      let obj = JSON.parse(decrypt(q));
      const date = obj.destinations[0].date;
      // const dates = [];
      
      for(let i=-3;i<=3;i++) {
        let reqObj = clone(obj);
        let newDate = moment(date).add(i,'d').format("YYYY-MM-DD");
        reqObj.destinations[0].date = newDate
  
        if(i===0) {
          let datesData = {date: newDate,data: returnData(res.data,res.cat.cheapest[0]),active: true};
          setFlightDate(oldDates => {
            if(oldDates.find(d => (JSON.stringify(d) === JSON.stringify(datesData)))) return oldDates
            if(oldDates.length >= 7)
              oldDates.shift();
            return ([...oldDates,datesData])});
        }
        else {
          // const res = await 
          fetchData(reqObj)
          .then((res) => {
            if(res.return) {
              // console.log('[p] res: ',res)
              // dates.push({reqObj})
              let datesData = ({date: newDate,req: reqObj,data: returnData(res.data,res.cat.cheapest[0])})
              // dates.push(datesData);
  
              // console.log('pushing d ',dates)
              // console.log(" ------------- ")
              // console.log('dates : ',dates)
          
              setFlightDate(oldDates => {
                if(oldDates.find(d => (JSON.stringify(d) === JSON.stringify(datesData)))) return oldDates
  
                if(oldDates.length >= 7)
                  oldDates.shift();
                return ([...oldDates,datesData])});
            }
          })
          
          // dates.push({date: '2023-06-15',data: {}})
          // setFlightDate(dates);
        }
      }
      
      function returnData(datas,i) {
        let offer = null;
        // console.log(' ----- ',i)
        // console.log(datas)
        try {
          offer = clone(datas[i])
        } catch(ex) {
          console.log('got error accessing data: ',ex)
        }
        return offer
      }
  
    }
    return () => clearTimeout(t);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch,handleSetCat,q])

  async function searchCalendars() {
    let req = JSON.parse(decrypt(q));

    req.destinations?.map(obj => {
      obj.departureLocation = obj.departureLocation?.iata || obj.departureLocation
      obj.arrivalLocation = obj.arrivalLocation?.iata || obj.arrivalLocation
      return true;
    })

    const res = await getCalendarSearch(req);
    if(res.return) {
      let data = res.data;
      let obj = data.itineraries || [];
      if(!Array.isArray(obj))
        obj = Object.values(data.itineraries)
      // if(Array.isArray(obj[0]))
      //     obj = obj[0]

      console.log(" => ",data)
      console.log(" => ",obj)

      let calendar = obj.map((item,i) => {
        let itierary = item;
        if(Array.isArray(item))
          itierary = item[0]
        else if(itierary.segments);
        else itierary = Object.values(item)[0]
            // itierary = Object.values(item)
        
        let date;
        if(Array.isArray(itierary?.segments))
            date = itierary?.segments[0]?.departureDate

        let reqObj = clone(req);
        let newDate = moment(date).format("YYYY-MM-DD");
        reqObj.destinations[0].date = newDate
    
        
        return {date,req:reqObj,data: {totalAmount: itierary?.totalAmount},active: i === 3}
      })


      // console.log(" --------- ")
      // console.log(calendar)
      setFlightDate(calendar)
    }
  }



  function search(reqObj) {
    if(reqObj) {
      let enc = encrypt(JSON.stringify(reqObj));
      navigate(`/search/flight/offers?q=${enc}`);
    }
  }

  function sortByCat(arr) {
    let temp = clone(fetchedData.current);
    let sortedData = [];
    arr.map(i => sortedData.push(temp[i]))

    setData(sortedData);
  }


  async function showDetail({i:_,...obj},bookable=[true],test) {
    console.log(test)
    if(obj)
      setCurDetail({...obj,bookable})

    if(!bookable.every(val => val)) return false;

    dispatch(setBookingData({...bookingData,offer: null,orderData: null,beforePrice: obj}))

    let userId = null;
    let userType = null;
    if(bookingData.as)
      userId = bookingData.as.id;
    if(bookingData?.userType)
      userType = bookingData?.userType


    const res = await getFlightOfferPrice({offer: obj,cwtCompanyName: bookingData?.cwt},userId,userType);
    if(res.return) {
      console.log(' ---- ',res.data)
      dispatch(setBookingData({...bookingData,offer: res.data,beforePrice: obj}))

      setCurDetail({...res.data,bookable})
    }
    setLockSelect(false);
  }

  function getCatInfo(ind,label) {
    // if(!ind) return false;
    try {
      let amount = fetchedData.current[ind]?.farePrice?.fareTotal;
      // amount = (amount.replace(",",""))
      amount = formatMoney(amount,fetchedData?.current[ind]?.currency)
      let duration = fetchedData.current[ind]?.segments[0]?.duration;
      let time = fetchedData.current[ind].segments[0].departureTime;
      let arrivalTime = fetchedData.current[ind].segments[0]?.flights?.at(-1).arrivalTime;
      let returnDepartureTime = fetchedData.current[ind].segments?.at(-1)?.flights?.at(0).departureTime;
      let returnArrivalTime = fetchedData.current[ind].segments?.at(-1)?.flights?.at(-1).arrivalTime;
      
      if(label?.toLowerCase() === 'earliestreturntakeoff')
        time = returnDepartureTime
      if(label?.toLowerCase() === 'earliestreturnlanding')
        time = returnArrivalTime

      if(label?.toLowerCase() === 'earliestlanding')
        time = arrivalTime

      time = time.split(":")
      // let h = parseInt(time[0]);
      // let m = parseInt(time[1]);
      if(['earliesttakeoff','earliestlanding','earliestreturnlanding','earliestreturntakeoff'].includes(label?.toLowerCase())) {
        time = time.join(':')
      } else {
        time = duration?.replaceAll('PT','')?.replaceAll('H','H ')
        // time = h+"h ";
        // if(m) time += m+'m'
      }
      
      return {amount,time};
    } catch(ex) {
      return "";
    }
  }

  
  return (
    <div className='w-full flex flex-col gap-2 bg-gray-100 py-4  '>
      <PriceTimeout />
      {/* <CheckFlightTimeout /> */}
      <div className='px-4'>
        <FlightSearchInput gotQ={q} />
      </div>
      <div className='bg-secondary flex justify-center'>
      <Tabs indicatorColor='inherit' textColor='inherit' value={0} scrollButtons allowScrollButtonsMobile variant='scrollable' className='div_mid'>
        {
          Object.entries(cat).map((obj,i) => {
            let catInfo = getCatInfo(obj[1][0],obj[0])
            return (
              <Tab key={i} className='p-5 text-center' sx={{textTransform: 'capitalize'}}
                onClick={() => sortByCat(obj[1])}
                label={(
                  <div>
                    <h5>{splitCapitals(obj[0])}</h5>
                    <div className='flex flex-col'>
                      <span>{catInfo && def.currency}{catInfo.amount}</span>
                      <small>{catInfo.time}</small>
                    </div>
                  </div>
                )}
              />
            )})
        }
      </Tabs>
      </div>
      {/* <div className='bg-secondary flex justify-center'>
        <Tabs indicatorColor='inherit' textColor='inherit' value={curFlightDate} scrollButtons allowScrollButtonsMobile variant='scrollable' className='div_mid'>
          {flightDate.map((obj,i) => (
            // .sort((p,c) => moment(p.date).diff(moment(c.date)))
            <Tab key={i} className='p-5 text-center div_targ' sx={{textTransform: 'capitalize'}}
              onClick={() => search(obj.req)}
             label={(
              <div>
                <h5>{obj.date && moment(obj.date).format("ddd DD MMM")}</h5>
                <p>
                  {obj.data && obj.data.totalAmount && def.currency}
                  {obj.data && formatMoney(obj.data.totalAmount,obj?.data?.currency)}
                </p>
              </div>
             )} />
          ))}
        </Tabs>
      </div> */}
      

      <div className='flex gap-4 '>
        <div className='bg-secondary self-end sticky bottom-0 rounded-2xl '>
          <FlightOfferFilter cats={cat} orgi={fetchedData.current} data={data} setData={setData} />
        </div>
        <div className='flex-1 flex flex-col gap-2'>
          <div className='flex gap-4 justify-between'>
            <p>Showing {pag.limit > data?.length ? data?.length : pag.limit} of <span className='text-red-400'>{data?.length} flights</span></p>
            <ShareFlightOffer offer={data?.slice(0,20)} />
          </div>

          {
            loading ?
              [...Array(4)]?.map((_,i) => (
                <FlightLoading key={i} />
              ))
            : data.length < 1 ?
              <h3 className='bg-secondary p-5 rounded-md flex items-center justify-center text-primary/30 uppercase'>{resMsg}</h3>
            : null              
          }
          {data.slice(0,pag.limit).map((obj,i) => (
            <FlightOfferDisplay key={i+''+obj.i} data={obj} lockSelect={lockSelect} setLockSelect={setLockSelect} showDetail={async (data,bookable) => await showDetail(data,bookable,obj)} />
          ))}
          {pag.limit < data.length ? 
            <button className='btn1' onClick={() => setPag(pag => ({...pag,limit: pag.limit + 5 > data.length ? data.length : pag.limit + 5}))}>Load More</button>
          : null}

          {/* <FlightOfferDisplay showDetail={(obj) => setCurDetail(obj)} /> */}
        </div>
        <div className='self-end sticky bottom-0'>
          <FlightOfferDetail data={data} setData={setData} obj={curDetail} />
        </div>
      </div>

      <DataLossModal openDataLoss={openDataLoss} setOpenDataLoss={setOpenDataLoss} />
    </div>
  )
}

